@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    color: #282e2c;
  }
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* ========== Ant Design ========== */
/* 
.ant-menu-light .ant-menu-item-selected {
  background-color: #DDF4ED;
} */

.table-radius-bottom .ant-table {
  border-radius: 8px !important;
}
